






























import { Component, Vue, Prop } from "vue-property-decorator";

import { CounterpartyCurrencyCode } from "@/accounting-portal/models/counterparties/CounterpartyCurrencyCode";

@Component
export default class InvoiceTotalCard extends Vue {
  @Prop() currencyCode!: CounterpartyCurrencyCode;
  @Prop() totalAmount!: number;
  @Prop() paidAmount!: number;
  @Prop() paidInvoices!: number;
  @Prop() totalInvoices!: number;

  get progress(): number {
    return (this.paidInvoices * 100) / this.totalInvoices;
  }

  getFormattedAmount(amount: number): string {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: this.currencyCode,
      maximumFractionDigits: 0,
    }).format(Math.round(amount));
  }
}
